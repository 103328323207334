
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

import "../../assets/stylesheets/tailwind.scss"
import "../../assets/stylesheets/mosaic.scss"

Rails.start()
window.Rails = Rails
ActiveStorage.start()
  
